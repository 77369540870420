/* allgemeine Infos und viewport-sx 320px */
.top-header-wrapper {
  background-color: var(--color-grey-dark);
  padding: calc(var(--gutter)*0.5) var(--gutter);
}

.top-header-kontakt {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-content: flex-end;
  text-align: center;
}

.top-header-kontakt p,
.top-header-kontakt a {
  color: white;
}

.top-header-wrapper a:hover {
  color: var(--color-grey-light);
}

.top-header-kontakt p {
  margin-bottom: 0;
  margin-right: calc(var(--gutter)*1.5);
}

.top-header-kontakt p span {
  font-size: 0.7rem;
  margin-right: 0.3rem;
}

.top-header-kontakt a span {
  font-size: 0.8rem;
}

/* Main- Header*/
.main-header-wrapper {
  background-color: white;
  padding: var(--gutter);
}

.main-header-logo {
  margin-bottom: var(--gutter-horizontal);
}

.main-header-logo a {
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
}

.main-header-logo a img {
  width: 16rem;
  height: 100%;
}

.main-header-desc h1,
.main-header-desc h2 {
  font-size: var(--font-size);
  line-height: 1.2;
  margin-bottom: calc(var(--gutter)*0.8);
  text-align: center;
}

/* Navigation */

.main-nav {
  background-color: var(--color-grey-light);
}

.hamburger-button {
  outline: none;
  border: medium none;
  color: var(--color-grey);
  font-weight: bold;
  padding: 0.5rem;
  background-color: var(--color-grey-light);
}

.hamburger-button:hover {
  background-color: #d7d7d7;
}

.hamburger-button svg {
  width: var(--font-size-h3);
  height: var(--font-size-h3);
  display: block;
  margin: 0 auto;
}

.mobile-page-title {
  display: flex;
  align-items: center;
  color: var(--color-grey);
  margin-bottom: 0;
  padding-left: var(--gutter);
  font-size: var(--font-size);
}

.main-navigation li:hover {
  background-color: var(--color-grey-light);
}

ul.main-navigation {
  list-style-type: none;
  padding-left: 0;
}

.main-navigation li a img {
  display: block;
  width: 13.75rem;
  height: auto;
  margin-top: var(--gutter);
}

.dropdown {
  position: absolute;
  left: 0;
  background-color: var(--color-background);
  margin-bottom: 0;
  width: 100%;
  padding: var(--gutter-horizontal);
  list-style-type: none;
}

.dropdown-list-item {
  padding: 0 var(--gutter);
}

.dropdown > li:last-of-type {
  border-right: none;
}

.dropdown > li:first-of-type {
  padding-left: 0;
}

.dropdown-list-item ul {
  font-size: var(--font-size-small);
  list-style-type: disc;
}

.dropdown-link {
  display: block;
  color: var(--font-color);
  text-decoration: none;
}

.submenu-2 {
  margin-bottom: var(--gutter-horizontal);
}

.dropdown-link-layer-2 {
  color: var(--font-color);
  text-decoration: none;
}

.dropdown-link:hover,
.dropdown-link-layer-2:hover {
  color: var(--color-primary);
}

.main-navigation .open {
  background-color: var(--color-grey-light);
  color: white;
}

.main-navigation .open + .dropdown {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  /* Main Header */
  .main-header-desc h1,
  .main-header-desc h2 {
    margin-bottom: 0;
  }

  /* Nav */
  .main-nav {
    margin-top: var(--gutter);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  header {
    z-index: 100;
  }

  /* TOP Header */
  .top-header-content-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-self: center;
  }

  .top-header-kontakt {
    width: 93%;
    justify-content: flex-start;
    align-self: flex-start;
  }

  .top-header-kontakt p,
  .top-header-kontakt a {
    font-size: var(--font-size-small);
    margin-right: calc(var(--gutter)*0.8);
  }

  .top-header-kontakt a:last-of-type {
    margin-right: 0;
  }

  .top-header-social a {
    color: white;
    margin-right: calc(var(--gutter)*0.2);
  }

  .top-header-social a:hover {
    color: var(--color-grey-light);
  }

  .top-header-social a:last-of-type {
    margin-right: 0;
  }

  /* Main Header */
  .main-header-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0;
    padding-bottom: calc(var(--gutter)*0.5);
  }

  .main-header-logo {
    width: 25%;
    margin-bottom: 0;
  }

  .main-header-logo a img {
    width: 100%;
    height: auto;
  }

  .main-header-desc {
    width: 75%;
    padding-right: var(--gutter);
  }

  .main-header-desc h1,
  .main-header-desc h2 {
    text-align: right;
  }


  /* Nav */
  .main-nav {
    background-color: transparent;
    display: flex;
    width: 100%;
    height: 1rem;
    justify-content: flex-end;
    margin-bottom: calc(var(--gutter)*0.5);
  }

  ul.main-navigation {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  ul.main-navigation li {
    position: relative;
    display: inline-block;
    background-color: white;
    margin-left: -0.25rem;
    padding-left: calc(var(--gutter)*0.45);
    padding-right: calc(var(--gutter)*0.45);
    border-right: 1px solid var(--font-color);
    font-size: var(--font-size-small);
  }

  ul.main-navigation li:first-of-type {
    margin-left: 0;
  }

  ul.main-navigation li:last-of-type {
    border-right: 0;
  }

  ul.main-navigation li:hover,
  ul.main-navigation li.active {
    background-color: white;
  }

  ul.main-navigation li a {
    color: var(--color-secondary);
    padding: 0;
  }

  ul.main-navigation li a:hover,
  ul.main-navigation li.active a,
  ul.main-navigation li:hover a {
    color: var(--color-primary);
    background-color: transparent;
  }

  .main-navigation li a img {
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(var(--gutter)*0.5);
  }

  /* 2. Ebene */
  .dropdown {
    display: none;
    position: absolute;
    left: 0;
    top: 1.9rem;
    background-color: var(--color-primary);
    margin-bottom: 0;
    width: 10rem;
    padding: 0;
    list-style-type: none;
    z-index: 10;
    box-shadow:0px 4px 5px 0px rgba(0,0,0, 0.2);
  }

  ul.main-navigation li.active .dropdown-link,
  ul.main-navigation li .dropdown-link,
  ul.main-navigation li:hover .dropdown-link,
  ul.main-navigation li .dropdown-link-layer-2,
  ul.main-navigation li:hover .dropdown-link-layer-2 {
    color: var(--font-color);
  }

  ul.dropdown li {
    display: block;
    width: 100%;
    padding: calc(var(--gutter)*0.5);
    margin-left: 0;
    font-size: var(--font-size-p);
    background-color: white;
    border-right: 0;
  }

  ul.dropdown li:first-of-type,
  ul.dropdown li {
    padding-left: var(--gutter);
  }

  ul.dropdown li:hover  {
    background-color: var(--color-grey-light);
  }

  .main-navigation .open {
    background-color: transparent;
    color: var(--color-primary);
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  header {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }

  .placeholder {
    height: 8.25rem;
  }

  /* Top Header */
  .top-header-kontakt p,
  .top-header-kontakt a {
    font-size: var(--font-size);
    margin-right: calc(var(--gutter)*1.5);
  }

  .top-header-social a span {
    font-size: var(--font-size-h4);
  }


  /* Main Header */
  .main-header-wrapper {
    padding-bottom: calc(var(--gutter)*0.7);
    align-items: flex-end;
  }

  .main-header-logo {
    width: 13%;
    margin-bottom: 0;
    margin-right: 5%;
  }

  .main-header-logo a img {
    width: 100%;
    height: auto;
  }

  .main-header-desc {
    display: flex;
    width: 82%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: var(--gutter);
  }

  .main-header-desc h1,
  .main-header-desc h2 {
    font-size: 0.8rem;
  }

  /* Nav */
  ul.main-navigation li {
    padding-left: calc(var(--gutter)*1.4);
    padding-right: calc(var(--gutter)*1.4);
    font-size: var(--font-size);
  }

  .main-navigation li a img {
    margin-bottom: var(--gutter);
  }

  /* 2. Ebene */
  .dropdown {
    top: 2.2rem;
    width: 12rem;
  }

  ul.dropdown li:first-of-type,
  ul.dropdown li {
    padding-left: var(--gutter);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .main-header-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .main-header-logo {
    width: 17%;
    margin-bottom: 0;
    margin-right: 0;
  }

  .main-header-content {
    width: 80%;
  }

  .main-header-desc {
    width: 100%;
  }

  .main-header-desc h1,
  .main-header-desc h2 {
    font-size: var(--font-size);
  }

  .main-nav {
    margin-top: 0.6rem;
  }

  .placeholder {
    height: 7.45rem;
  }


  /* Nav */
  .main-navigation li a img {
    width: 11rem;
  }

  /* 2. Ebene */
  .dropdown {
    left: 0;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .placeholder {
    height: 7.45rem;
  }

  /* Top Header */
  .top-header-kontakt p,
  .top-header-kontakt a {
    font-size: var(--font-size);
    margin-right: calc(var(--gutter)*2);
  }

  /* Main Header */
  .main-header-logo {
    width: 16%;
  }

  .main-header-content {
    width: 71%;
  }

  .main-header-desc {
    padding-left: calc(var(--gutter)*0.5);
  }

  .main-header-desc h1,
  .main-header-desc h2 {
    font-size: var(--font-size);
  }

  /* Nav */
  ul.main-navigation {
    margin-right: 0;
    margin-left: 0;
  }

  ul.main-navigation li {
    padding-left: calc(var(--gutter)*2);
    padding-right: calc(var(--gutter)*2);
  }

  ul.main-navigation li:last-of-type {
    border-right: 0;
    padding-right: 1rem;
  }

  .main-navigation li a img {
    width: 11.5rem;
  }

  /* 2. Ebene */
  .dropdown {
    top: 2rem;
    width: 13rem;
  }

  ul.dropdown li:first-of-type,
  ul.dropdown li {
    padding-left: var(--gutter);
  }
}
