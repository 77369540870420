/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
  display: block;
}

textarea,
.pure-form textarea {
  display: block;
}

.pure-form select {
  height: 2.5em;
}
