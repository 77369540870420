/* allgemeine Infos und viewport-sx 320px */
/* allgemeine Infos und viewport-sx 320px */
.sidebar-wrapper {
  margin-top: 1rem;
}
.sidebar-box {
  background-color: white;
  border-bottom: 1px solid var(--color-grey);
  margin-bottom: 1rem;
}
.sidebar-box:not(.ansprechpartner-box) {
  padding: 1rem;
}
.sidebar-box  h2 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  margin-bottom: var(--gutter);
}
.sidebar-box a {
  display: block;
  color: var(--font-color);
}
.sidebar-box a:hover {
  color: var(--color-grey);
}
.ansprechpartner-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.ansprechbartner-img {
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.ansprechbartner-img img {
  width: 100%;
  height: auto;
}
.ansprechpartner-content {
  width: 100%;
  padding: var(--gutter);
}
.ansprechpartner-content h6 {
  margin-bottom: 0;
}
.ansprechpartner-content p {
  margin-bottom: var(--gutter);
}

.ansprechpartner-content a span {
  margin-right: calc(var(--gutter)*0.5);
  vertical-align: middle;
}

/* Kontakt */
.kontakt-box {
  padding: var(--gutter);
}

.kontakt-box-adresse h6,
.kontakt-box-adresse-kontakt {
  margin-bottom: var(--gutter);
}

.kontakt-box-adresse p,
.kontakt-box-adresse-kontakt p,
.kontakt-box-adresse-kontakt a {
  font-size: var(--font-size-small);
}

.kontakt-box-adresse-kontakt span {
  margin-right: calc(var(--gutter)*0.5);
  vertical-align: middle;
}

.kontakt-box-adresse-kontakt p {
  margin-bottom: 0;
}

.kontakt-box-oeffnung h5 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  margin-bottom: calc(var(--gutter)*0.5);
}

.kontakt-box-oeffnung td {
  font-size: 0.78rem;
  padding-top: 0;
  padding-bottom: 0;
}

.kontakt-box-oeffnung tr:nth-of-type(3) td {
  padding-top: calc(var(--gutter)*0.5);
}

.kontakt-box-oeffnung tr td:first-of-type {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
}

.kontakt-box form img {
  display: block;
  width: 1.5rem;
  height: 100%;
  margin-left: auto;
}

.kontakt-box select {
  width: 100%;
  height: 2.5rem;
  margin-top: 0.5rem;
}
#sidebar-kontakt {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.kontakt-box-adresse {
  width: 100%;
}
.kontakt-box-oeffnung {
  width: 100%;
}
/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .ansprechbartner-img {
    width: 40%;
  }
  .ansprechpartner-content {
    width: 60%;
  }
  .kontakt-box-adresse {
    width: 50%;
  }
  .kontakt-box-oeffnung {
    width: 50%;
  }

}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .kontakt-box-adresse p,
  .kontakt-box-adresse-kontakt p,
  .kontakt-box-adresse-kontakt a,
  .kontakt-box-oeffnung td {
    font-size: var(--font-size);
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .sidebar-wrapper {
    margin-top:0;
  }
  .kontakt-box-adresse {
    width: 100%;
  }
  .kontakt-box-oeffnung {
    width: 100%;
  }
  /* Ansprechpartner */
  .sidebar-box {
    margin-bottom: 0;
  }
  .ansprechbartner-img {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .ansprechbartner-img img {
    width: 100%;
    height: auto;
    align-self: center;
  }
  .ansprechpartner-content {
    padding: 0.7rem;
    width: 100%;
  }

  .kontakt-box-adresse h6,
  .kontakt-box-adresse-kontakt {
    margin-bottom: var(--gutter);
  }

  .kontakt-box-adresse p,
  .kontakt-box-adresse-kontakt p,
  .kontakt-box-adresse-kontakt a {
    font-size: var(--font-size-small);
  }

  .kontakt-box-adresse-kontakt p {
    margin-bottom: 0;
  }

  .kontakt-box-oeffnung h5 {
    font-family:'Helvetica Neue LT W01_71488914';
    font-weight: normal;
    margin-bottom: calc(var(--gutter)*0.5);
  }

  .kontakt-box-oeffnung td {
    font-size: 0.78rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .kontakt-box-oeffnung tr:nth-of-type(3) td {
    padding-top: calc(var(--gutter)*0.5);
  }

  .kontakt-box-oeffnung tr td:first-of-type {
    font-family:'Helvetica Neue LT W01_71488914';
    font-weight: normal;
  }

  .kontakt-box form img {
    display: block;
    width: 1.5rem;
    height: 100%;
    margin-left: auto;
  }

  .kontakt-box select {
    width: 100%;
    height: 2.5rem;
    margin-top: 0.5rem;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  /* Kontakt*/
  .kontakt-box-adresse p,
  .kontakt-box-adresse-kontakt p,
  .kontakt-box-adresse-kontakt a {
    font-size: var(--font-size);
  }

  .kontakt-box-oeffnung td {
    font-size: var(--font-size);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  /* Ansprechpartner */
  .ansprechpartner-box {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  /* Kontakt */
  .kontakt-box-oeffnung table {
    width: 17rem;
  }
}
