/* allgemeine Infos und viewport-sx 320px */
.kontaktformular h2 {
  margin-bottom: var(--gutter);
}

#contactBottem {
  margin-left: -0.75rem;
}

#contactBottem input,
#contactBottem select,
#contactBottem textarea {
  margin-bottom: calc(var(--gutter)*0.5);
}

#contactBottem fieldset {
  padding-top: calc(var(--gutter)*0.5);
  padding-bottom: 0;
}

.datepicker {
  width: 100%;
}

.g-recaptcha {
  margin-bottom: var(--gutter);
}

/* Anfahrt */
.anfahrt {
  background-color: white;
  border-bottom: 1px solid var(--color-grey);
  padding: var(--gutter);
  margin-top: var(--gutter);
}

.maps-container {
  height: 12rem;
  width: 100%;
}


/* viewport-sm 344px */
@media screen and (min-width: 21.5rem) {
  #contactBottem {
    margin-left: 0;
  }
}

/* viewport-sm 352px */
@media screen and (min-width: 22rem) {
  .kontaktformular {
    background-color: white;
    border-bottom: 1px solid var(--color-grey);
    padding: var(--gutter) calc(var(--gutter)*0.5);
  }
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .kontaktformular {
    background-color: white;
    border-bottom: 1px solid var(--color-grey);
    padding: var(--gutter);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  #contactBottem .info-form-vorname,
  #contactBottem .info-form-ort,
  #contactBottem .info-form-email,
  #contactBottem .info-form-date {
    margin-right: calc(var(--gutter)*0.5);
  }

  #contactBottem .info-form-nachname,
  #contactBottem .info-form-plz,
  #contactBottem .info-form-telefon,
  #contactBottem .info-form-time,
  #contactBottem .info-form-fahrzeug {
    margin-left: calc(var(--gutter)*0.5);
  }

  /* Anfahrt */
  .maps-container {
    height: 15rem;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .anfahrt {
    margin-top: calc(var(--gutter)*1.25);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .anfahrt {
    margin-top: calc(var(--gutter)*1.5);
  }

  .maps-container {
    height: 18rem;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .anfahrt {
    margin-top: calc(var(--gutter)*1.75);
  }
}
