/* allgemeine Infos und viewport-sx 320px */
.mobile-navigation {
  font-size: var(--font-size);
}

.mobile-navigation a {
  display: block;
  padding: calc(var(--gutter)*0.8);
}

.mobile-navigation .first-layer {
  color: var(--font-color);
}
.mobile-navigation .first-layer:hover {
  color: var(--font-color);
}
.mobile-navigation .first-layer + ul {
  display: none;
  list-style-type: none;
}

.mobile-navigation .first-layer.open + ul {
  display: block;
}

.mobile-navigation .first-layer::before {
  border: none;
}

.mobile-navigation .dropdown-list-item {
  border: none;
  padding: 0;
}

.mobile-navigation .dropdown-list-item a {
  margin-bottom: 0;
}

.mobile-navigation .dropdown {
  background-color: transparent;
  position: relative;
  padding: 0;
  padding-left: calc(var(--gutter) * 2);
}

.mobile-navigation .dropdown-link {
  color: var(--font-color);
}

.main-navigation li:hover .dropdown-link {
  color: var(--font-color);
}

.mobile-navigation li:hover .dropdown-link:hover {
  color: var(--color-grey-dark);
}

.mobile-navigation .dropdown-link-layer-2 {
  color: var(--font-color);
}

.mobile-navigation li:hover .dropdown-link-layer-2 {
  font-size: var(--font-size);
  color: var(--font-color);
}

.mobile-navigation li:hover .dropdown-link-layer-2:hover {
  font-size: var(--font-size);
  color: var(--color-grey-dark);
}

.mobile-navigation .dropdown-list-item ul {
  list-style-type: none;
  margin-bottom: 0;
}

.mobile-navigation .dropdown-list-item li {
  padding: 0;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
}
