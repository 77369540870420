/* allgemeine Infos und viewport-sx 320px */
.newsboxen-wrapper h2 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  margin-bottom: var(--gutter);
}

.newsbox {
  background-color: white;
  margin-top: var(--gutter);
  border-bottom: 1px solid var(--color-grey);
}

article:first-of-type .newsbox {
  margin-top: 0;
}

.newsbox .news-img {
  position: relative;
}

.newsbox .news-img::before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
  z-index: 10;
}

.newsbox .news-img img {
  width: 100%;
  height: auto;
}

.newsbox .news-content {
  padding: var(--gutter);
}

.newsbox .news-content h5 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  margin-bottom: var(--gutter);
}

.newsbox .news-content a {
  display: table;
  margin-left: auto;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .newsbox {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  .newsbox .news-img {
    display: flex;
    width: 35%;
    height: 14rem;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .newsbox .news-img img {
    width: auto;
    height: 100%;
  }

  .newsbox .news-img::before {
    bottom: auto;
    top: 50%;
    left: auto;
    right: 0;
    transform: translateX(0);
    transform: translateY(-50%);
    width: 0px;
    height: 0px;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent  transparent;
  }

  .newsbox .news-content {
    width: 65%;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .newsboxen-wrapper h2 {
    font-size: var(--font-size-h1);
  }

  .newsbox .news-img img {
    width: 100%;
    height: auto;
  }

  .newsbox .news-content {
    padding-right: calc(var(--gutter)*2);
    padding-left: calc(var(--gutter)*2);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .newsbox {
    width: auto;
    align-items: flex-start;
  }

  article:nth-of-type(2) .newsbox {
    margin-top: 0;
  }

  article:nth-of-type(even) .newsbox {
    margin-left: var(--gutter);
  }

  article:nth-of-type(odd) .newsbox {
    margin-right: var(--gutter);
  }

  .newsbox .news-img {
    height: 14rem;
  }

  .newsbox .news-img img {
    width: auto;
    height: 100%;
  }
}
