.neuwagen--liste {
  list-style: none;
  -webkit-padding-start: 0;
  -moz-padding-start: 0;
}
.neuwagen--subliste {
  list-style: none;
  -webkit-padding-start: 0;
  -moz-padding-start: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
ul.neuwagen--subliste li {
  width: 100%;
  margin-left: 0;
}
.neuwagen--subliste a {
  text-decoration: none;
  color: #000000;
text-align: center;
}
.neuwagen--subliste a:hover {
  color: var(--color-primary);
}

.neuwagen--detail-footer {
  display: flex;
  margin-top: var(--gutter);
  margin-bottom: var(--gutter);
}
.neuwagen--detail--price {
  margin-left: auto;
  font-size: var(--font-size-h3);
  font-weight: 700;
}
.neuwagen--detail--price small{
  font-size: var(--font-size-h5);
}
.neuwagen--img {
  width: 100%;
}
@media screen and (min-width: 35.5rem) {
  ul.neuwagen--subliste li {
    width: 50%;
  }
}
@media screen and (min-width: 48rem) {

}
@media screen and (min-width: 64rem) {
  ul.neuwagen--subliste li {
    width: 33.333333%;
  }
}