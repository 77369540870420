
.modal {
  background: rgba(50, 50, 50, 0.8) none repeat scroll 0 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  overflow: scroll;
  width: 100%;
  height: 100vh;
}

.modal-content {
  background: #fff;
  top: 0;
  width: 100vw;
  padding: 4rem 1.5rem;
  position: relative;
}

.modal-header {
  position: relative;
  margin-bottom: 1rem;
  display: inline-block;
  width: 100%;
}

.modal-header button.close {
  float: right;
}
.modal-header h4 {
  width: 80%;
}
/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .modal {
    overflow: hidden;
  }

  .modal-content {
    top: 5rem;
    width: 75vw;
    left: 12.5vw;
    margin-bottom: 4rem;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .modal-content {
    top: 4rem;
    width: 50vw;
    left: 25vw;
    padding: 2.5rem 3.438rem 2.5rem 2.5rem;
  }
}

/* viewport-lg 1280px */
@media screen and (min-width: 80rem) {
}

