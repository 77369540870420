/* allgemeine Infos und viewport-sx 320px */
.main-footer {
  background-image: url('img/bg.jpg');
  background-repeat: repeat;
  background-position: top left;
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

.main-footer p {
  margin-bottom: 0;
}

.main-footer-content {
  margin-top: calc(var(--gutter)*2);
}

.main-footer-content:first-of-type {
  margin-top: 0;
}

.main-footer p,
.main-footer-content,
.main-footer-content a {
  color: white;
}

.main-footer-content a:hover {
  color: var(--color-grey-light);
}

.main-footer-content h5 {
  text-transform: uppercase;
  margin-bottom: calc(var(--gutter)*0.5);
}

.main-footer-content ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.main-footer-content ul a {
  display: block;
  border-bottom: 1px solid white;
  padding-bottom: calc(var(--gutter)*0.5);
  padding-top: calc(var(--gutter)*0.5);
}

.main-footer-content p {
  margin-bottom: var(--gutter);
}

/* Subfooter */
.sub-footer {
  background-color: var(--color-grey-light);
  padding-top: var(--gutter);
  padding-bottom: calc(var(--gutter)*6.5);
}

.sub-footer p {
  margin-bottom: 0;
}

.sub-footer a {
  color: var(--font-color);
}

.sub-footer a:hover {
  color: var(--color-grey);
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .main-footer-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .main-footer-wrapper p {
    margin-top: var(--gutter);
    width: 100%;
  }

  .main-footer-content {
    width: 48%;
    margin-right: 4%;
  }

  .main-footer-content:nth-of-type(2n) {
    margin-right: 0;
  }

  .main-footer-content:nth-of-type(2) {
    margin-top: 0;
  }

  .sub-footer {
    padding-bottom: calc(var(--gutter)*5.5);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .main-footer-content {
    width: 46%;
    margin-right: 8%;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .main-footer-content {
    width: 23.5%;
    margin-right: 2%;
    margin-top: 0;
  }

  .main-footer-content:nth-of-type(2n) {
    margin-right: 2%;
  }

  .main-footer-content:nth-of-type(4n) {
    margin-right: 0;
  }

  .main-footer-content p,
  .main-footer-content a {
    font-size: var(--font-size-small);
  }

  /* Subfooter */
  .sub-footer {
    background-color: var(--color-grey-light);
    padding-top: var(--gutter);
    padding-bottom: var(--gutter);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .main-footer-content {
    width: 22%;
    margin-right: 4%;
  }

  .main-footer-content:nth-of-type(2n) {
    margin-right: 4%;
  }

  .main-footer-content:nth-of-type(4n) {
    margin-right: 0;
  }

  .main-footer-content p,
  .main-footer-content a {
    font-size: var(--font-size);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
}
