/*
 * Filename: typo.css
 *
 * Primary stylesheet which imports every other file.
 */

 /*
  font-family:'Helvetica Neue LT W01_55 Roman';
  font-family:'Helvetica Neue LT W01_41488878';
  font-family:'Helvetica Neue LT W01_71488914';
  font-family:'Helvetica Neue LT W01_56 It';
 */

 @font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?assaps');
  src:  url('fonts/icomoon.eot?assaps#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?assaps') format('truetype'),
    url('fonts/icomoon.woff?assaps') format('woff'),
    url('fonts/icomoon.svg?assaps#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family:'Helvetica Neue LT W01_55 Roman';
  font-size: var(--font-size);
  line-height: 1.3;

  color: var(--font-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin-top: 0;
  margin-bottom: var(--gutter-horizontal);
}

h1 {
  font-size: var(--font-size-h1);
  font-weight: normal;
  line-height: calc(var(--line-height) * .75);
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: normal;
  line-height: calc(var(--line-height) * .8);
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: normal;
  line-height: calc(var(--line-height) * .8);
}

h4 {
  font-size: var(--font-size-h4);
  font-weight: normal;
  line-height: calc(var(--line-height) * .8);
}

h5 {
  font-size: var(--font-size-h5);
  font-weight: normal;
  line-height: calc(var(--line-height) * .8);
}

h6 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-size: var(--font-size-h6);
  font-weight: normal;
  line-height: calc(var(--line-height) * .8);
}

small {
  font-size: var(--font-size-small);
}

ul {
  padding-left: var(--gutter-horizontal);
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

a:hover {
  color: var(--color-primary-hover);
}

button {
  border: 0;
}

@media screen and (--viewport-md) {
}
