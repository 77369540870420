/* allgemeine Infos und viewport-sx 320px */
.startbox {
  margin-bottom: var(--gutter);
}

.startbox,
.startbox-kontakt {
  background-color: white;
  border-bottom: 1px solid var(--color-grey);
  padding-bottom: var(--gutter);
}

.startbox-kontakt {
  padding-top: var(--gutter);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.startbox .startbox-img {
  position: relative;
}

.startbox .startbox-img::before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
  z-index: 10;
}

.startbox .startbox-content {
  padding-top: var(--gutter);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.startbox-content h5 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  margin-bottom: var(--gutter);
}

.startbox-content p {
  margin-bottom: var(--gutter);
}

.startbox a.btn-primary {
  display: block;
  text-align: center;
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}

/* Kontakt */
.startbox-kontakt .startbox-content h6 {
  margin-bottom: 0;
}

.startbox-kontakt .startbox-content-kontakt {
  margin-bottom: var(--gutter);
}

.startbox-kontakt .startbox-content-kontakt a {
  color: var(--font-color);
}

.startbox-kontakt .startbox-content-kontakt a:hover {
  color: var(--color-grey);
}

.startbox-kontakt .startbox-content-kontakt a,
.startbox-kontakt .startbox-content-kontakt p {
  display: block;
  margin-bottom: calc(var(--gutter)*0.2);
}

.startbox-kontakt .startbox-content-kontakt span {
  margin-right: calc(var(--gutter)*0.5);
  vertical-align: middle;
}

.startbox-kontakt .startbox-content table {
  max-width: 17rem;
  margin-bottom: 0;
}

.startbox-kontakt .startbox-content tr:nth-of-type(3) td {
  padding-top: calc(var(--gutter)*0.5);
}

.startbox-kontakt .startbox-content td {
  font-size: var(--font-size-small);
  padding-top: 0;
  padding-bottom: 0;
}

.startbox-kontakt .startbox-content tr td:first-of-type {
    font-family:'Helvetica Neue LT W01_71488914';
    font-weight: normal;
  }

.startbox-kontakt .startbox-content form img {
  display: block;
  width: 1.5rem;
  height: 100%;
  margin-left: auto;
}

.startbox-kontakt .startbox-content select {
  width: 100%;
  height: 2.5rem;
  margin-top: 1.5rem;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .startbox-kontakt .startbox-content p,
  .startbox-kontakt .startbox-content a {
    font-size: var(--font-size-small);
  }

  .startbox-kontakt .startbox-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
  }

  .startbox-content-innerwrapper {
    margin-right: calc(var(--gutter)*3);
  }

  .startbox-kontakt .startbox-content form {
    width: 100%;
  }
}

@media screen and (min-width: 41.75rem) {
  .startbox-content-innerwrapper {
    margin-right: calc(var(--gutter)*9);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .startboxen-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .startbox {
    position: relative;
    width: 29%;
    margin-bottom: 0;
  }

  .startbox-kontakt {
    display: flex;
    flex-wrap: wrap;
    width: 37%;
  }

  .startbox .startbox-content {
    margin-bottom: calc(var(--gutter)*4);
  }

  .startbox a.btn-primary {
    position: absolute;
    bottom: var(--gutter);
    left: 50%;
    transform: translateX(-50%);
    width: 85%;
    margin-left: 0;
    margin-right: 0;
  }

  .startbox-content-innerwrapper {
    margin-right: 0;
  }

  .startbox-kontakt .startbox-content {
    justify-content: flex-start;
  }

  .startbox-kontakt .startbox-content form {
    margin-top: auto;
  }
}

/* Zusatz Viewport 896px*/
@media screen and (min-width: 56rem) {
  .startbox {
    width: 32%;
  }

  .startbox a.btn-primary {
    position: absolute;
    bottom: var(--gutter);
    width: 89%;
  }

  .startbox-kontakt {
    width: 33%;
  }

  .startbox-kontakt .startbox-content p,
  .startbox-kontakt .startbox-content a {
    font-size: var(--font-size);
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .startbox,
  .startbox-kontakt {
    width: 32%;
  }

  .startbox-kontakt .startbox-content td {
    font-size: var(--font-size);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .startbox,
  .startbox-kontakt {
    width: 32%;
  }

  .startbox a.btn-primary {
    width: 92%;
  }

  .startbox-kontakt .startbox-content {
    justify-content: flex-start;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .startbox a.btn-primary {
    width: 93%;
  }

  .startbox-kontakt {
    padding-left: calc(var(--gutter)*2);
    padding-right: calc(var(--gutter)*2);
  }
}
