/* allgemeine Infos und viewport-sx 320px */
.schnellzugriff-wrapper {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 100;
}

.schnellzugriff-wrapper div {
  display: flex;
  flex-wrap: wrap;
  width: 24.5%;
  height: 5rem;
  justify-content: center;
  align-items: flex-end;
  background-color: var(--color-secondary);
  padding-bottom: calc(var(--gutter)*0.5);
  padding-top: calc(var(--gutter)*0.5);
}

.schnellzugriff-wrapper a {
  font-size: 0.7rem;
  text-align: center;
  width: 100%;
  color: white;
}

.schnellzugriff-wrapper div:hover {
  background-color: var(--color-primary);
}

.schnellzugriff-wrapper div img {
  width: 1.5rem;
  height: 1.5rem;
}

.schnellzugriff-wrapper div:last-of-type img {
  height: 1.5rem;
}

/* viewport 366px */
@media screen and (min-width: 22.9rem) {
  .schnellzugriff-wrapper div {
    padding-left: calc(var(--gutter)*0.5);
    padding-right: calc(var(--gutter)*0.5);
  }
}

/* viewport 438px */
@media screen and (min-width: 27.375rem) {
  .schnellzugriff-wrapper div {
    padding-left: calc(var(--gutter)*1.2);
    padding-right: calc(var(--gutter)*1.2);
  }
}

/* viewport 530px */
@media screen and (min-width: 33.125rem) {
  .schnellzugriff-wrapper div {
    height: 4rem;
    padding-left: calc(var(--gutter)*1.5);
    padding-right: calc(var(--gutter)*1.5);
  }
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  .schnellzugriff-wrapper div {
    padding-left: calc(var(--gutter)*0.5);
    padding-right: calc(var(--gutter)*0.5);
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .schnellzugriff-wrapper {
    width: 14rem;
    top: 45%;
    bottom: auto;
    left: 0;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 100;
  }


  .schnellzugriff-wrapper div {
    left: -10.5rem;
    flex-wrap: nowrap;
    width: 14rem;
    height: 3rem;
    padding: 0;
    margin-bottom: 2px;
    align-items: center;
    transition: left .5s;
  }

  .schnellzugriff-wrapper div:hover {
    left: 0;
    margin-left: 0;
    transition: left .5s;
  }

  .schnellzugriff-wrapper a {
    margin-left: calc(var(--gutter)*0.5);
    text-align: right;
    font-size: var(--font-size-small);
  }

  .schnellzugriff-wrapper a:first-of-type {
    order: 2;
    width: 4.5rem;
    text-align: center;
  }

  .schnellzugriff-wrapper a:nth-of-type(2) {
    order: 1;
    width: 11.5rem;
  }

  .schnellzugriff-wrapper img {
    width: 1.2rem;
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
}
