/* allgemeine Infos und viewport-sx 320px */
/* allgemeine Abständer der Sections Startseite */
.startboxen-wrapper,
.angebotsboxen-start-wrapper,
.newsboxen-wrapper {
  padding-top: var(--gutter-horizontal);
  padding-bottom: var(--gutter-horizontal);
}

/* allgemeine Abständer der Sections Unterseiten */
.news article,
.content-innerwrapper article,
.gebrauchtwagen-wrapper,
.gebrauchtwagen-detail-wrapper,
.kontakt-wrapper {
  margin-top: calc(var(--gutter)*0.75);
  margin-bottom: calc(var(--gutter)*0.75);
}

.content-wrapper article,
.news article {
  background-color: white;
  border-bottom: 1px solid var(--color-grey);
  padding: var(--gutter);
}

/* Layouts */
.content-left a img,
.content-right a img,
.content-above a img {
  margin-bottom: 1rem;
}

.content-below a img {
  margin-top: 1rem;
}

.content-below a:first-of-type img {
  margin-top: 0;
}

.content-right a:last-of-type img {
  margin-bottom: 0;
}

.layout-1  a.oneImg,
.layout-2  a.oneImg,
.layout-3  a.oneImg,
.layout-4  a.oneImg {
  width: 100%;
  margin-right: 0;
}

.content-wrapper .content-right p:last-of-type,
.content-wrapper .content-below p:last-of-type {
  margin-bottom: 0;
}

.content-wrapper ul li {
  margin-top: calc(var(--gutter)*0.5);
  margin-left: 20px;
}

.content-wrapper ul li:first-of-type {
  margin-top: 0;
}

/* Bildnachweis Impressum */
.bildnachweis {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
#formRoute{
  margin-bottom: 1rem;
}
#formRoute  input,
#formRoute select {
  margin-bottom: 0.5rem;
}
/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  /* Layouts */
  .layout-4 .content-below,
  .layout-1 .content-left,
  .layout-2 .content-right,
  .layout-3 .content-above {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .layout-4 .content-below a,
  .layout-2 .content-right a,
  .layout-1 .content-left a,
  .layout-3 .content-above a {
    width: 32%;
    margin-right: 2%;
  }

  .layout-4 .content-below a:nth-of-type(3n),
  .layout-1 .content-left a:nth-of-type(3n),
  .layout-2 .content-right a:nth-of-type(3n),
  .layout-3 .content-above a:nth-of-type(3n) {
    margin-right: 0;
  }

  .content-below a:nth-of-type(2) img,
  .content-below a:nth-of-type(3) img {
    margin-top: 0;
  }

  .layout-4  a.oneImg,
  .layout-1  a.oneImg,
  .layout-2  a.oneImg,
  .layout-3  a.oneImg {
    width: 100%;
    margin-right: 0;
  }

  .fileupload-layout {
    margin-top: var(--gutter);
  }

  .fileupload-layout a img,
  .fileupload-layout a img,
  .fileupload-layout a img,
  .fileupload-layout a img {
    width: 0.7rem;
    height: 100%;
    margin-right: calc(var(--gutter)*0.3);
    vertical-align: sub;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  /* allgemeine Abständer der Sections Startseite */
  .startboxen-wrapper,
  .angebotsboxen-start-wrapper,
  .newsboxen-wrapper {
    padding-top: calc(var(--gutter)*2);
    padding-bottom: calc(var(--gutter)*2);
  }

  /* allgemeine Abständer der Sections Unterseiten */
  .news article,
  .content-innerwrapper article,
  .gebrauchtwagen-wrapper,
  .gebrauchtwagen-detail-wrapper,
  .kontakt-wrapper {
    margin-top: calc(var(--gutter)*1);
    margin-bottom: calc(var(--gutter)*1);
  }

  /* Layouts */
  .content-left a:last-of-type img,
  .content-right a:last-of-type img {
    margin-bottom: 0;
  }

  .layout-2 .content-right a,
  .layout-1 .content-left a {
    width: 100%;
    margin-right: 0;
  }

  .layout-1 .content-left a:nth-of-type(2n),
  .layout-2 .content-right a:nth-of-type(2n) {
    margin-right: 0;
  }

  .layout-2 .content-left {
    margin-right: calc(var(--gutter)*2);
  }

  .layout-1 .content-right {
    margin-left: calc(var(--gutter)*2);
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  /* allgemeine Abständer der Sections Startseite */
  .startboxen-wrapper,
  .angebotsboxen-start-wrapper,
  .newsboxen-wrapper {
    padding-top: calc(var(--gutter)*2.5);
    padding-bottom: calc(var(--gutter)*2.5);
  }

  /* allgemeine Abständer der Sections Unterseiten */
  .content-wrapper,
  .sidebar-ansprechpartner-wrapper,
  .sidebar-kontakt-wrapper {
    padding-top: calc(var(--gutter)*1.25);
    padding-bottom: calc(var(--gutter)*1.25);
  }

  .news article,
  .content-innerwrapper article,
  .gebrauchtwagen-wrapper,
  .gebrauchtwagen-detail-wrapper,
  .kontakt-wrapper {
    margin-top: calc(var(--gutter)*1.25);
    margin-bottom: calc(var(--gutter)*1.25);
  }

  .news-wrapper,
  .content-innerwrapper,
  .gebrauchtwagen-wrapper,
  .gebrauchtwagen-detail-wrapper,
  .kontakt-wrapper {
    margin-right: calc(var(--gutter)*2);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .startboxen-wrapper,
  .angebotsboxen-start-wrapper,
  .newsboxen-wrapper {
    padding-top: calc(var(--gutter)*3);
    padding-bottom: calc(var(--gutter)*3);
  }

  /* allgemeine Abständer der Sections Unterseiten */
  .content-wrapper,
  .sidebar-ansprechpartner-wrapper,
  .sidebar-kontakt-wrapper {
    padding-top: calc(var(--gutter)*1.5);
    padding-bottom: calc(var(--gutter)*1.5);
  }

  .news article,
  .content-innerwrapper article,
  .gebrauchtwagen-wrapper,
  .gebrauchtwagen-detail-wrapper,
  .kontakt-wrapper {
    margin-top: calc(var(--gutter)*1.5);
    margin-bottom: calc(var(--gutter)*1.5);
  }

  /* Layouts */
  .content-below a:nth-of-type(4) img {
    margin-top: 0;
  }

  .layout-4 .content-below a,
  .layout-3 .content-above a {
    width: 23.5%;
    margin-right: 2%;
  }

  .layout-4 .content-below a:nth-of-type(3n),
  .layout-3 .content-above a:nth-of-type(3n) {
    margin-right: 2%;
  }

  .layout-4 .content-below a:nth-of-type(4n),
  .layout-3 .content-above a:nth-of-type(4n) {
    margin-right: 0;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .startboxen-wrapper,
  .angebotsboxen-start-wrapper,
  .newsboxen-wrapper {
    padding-top: calc(var(--gutter)*3.5);
    padding-bottom: calc(var(--gutter)*3.5);
  }

  /* allgemeine Abständer der Sections Unterseiten */
  .content-wrapper,
  .sidebar-ansprechpartner-wrapper,
  .sidebar-kontakt-wrapper {
    padding-top: calc(var(--gutter)*1.75);
    padding-bottom: calc(var(--gutter)*1.75);
  }

  .news article,
  .content-innerwrapper article,
  .gebrauchtwagen-wrapper,
  .gebrauchtwagen-detail-wrapper,
  .kontakt-wrapper {
    margin-top: calc(var(--gutter)*1.75);
    margin-bottom: calc(var(--gutter)*1.75);
  }
}
