/* allgemeine Infos und viewport-sx 320px */
.angebotsboxen-start-wrapper {
  background-color: white;
  box-shadow:inset 0px 0px 10px 0px rgba(0,0,0, 0.5);
}

.angebotsboxen-start-wrapper h2 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  margin-bottom: var(--gutter);
}

.angebotsbox-start,
.angebotsboxen-suche {
  background-color: var(--color-grey-light);
  border-bottom: 1px solid var(--color-grey);
  padding-bottom: var(--gutter);
}

.angebotsbox-start {
  margin-bottom: var(--gutter);
}

.angebotsbox-start-content {
  position: relative;
  padding-top: var(--gutter);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.angebotsbox-start-content h5 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  margin-bottom: var(--gutter);
}

.angebotsbox-start-content p {
  margin-bottom: var(--gutter);
}

.angebotsbox-start a.btn-primary {
  display: block;
  text-align: center;
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}

/* Suche */
.angebotsboxen-suche-head {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.angebotsboxen-suche-head div:first-of-type {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey);
}

.angebotsboxen-suche-head span {
  color: white;
}

.angebotsboxen-suche-head div:nth-of-type(2) {
  display: flex;
  width: 100%;
  height: 2.5rem;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-secondary);
}

.angebotsboxen-suche-head h5 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  color: white;
  margin-bottom: 0;
  margin-left: var(--gutter);
}

.angebotsboxen-suche form {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.angebotsboxen-suche fieldset {
  padding-top: var(--gutter);
  padding-bottom: 0;
}

.angebotsboxen-suche h6 {
  margin-bottom: calc(var(--gutter)*0.5);
}

#formFahrzeugsuche input,
#formFahrzeugsuche select,
#formFahrzeugsuche textarea {
  margin-bottom: calc(var(--gutter)*0.5);
}

.angebotsboxen-suche label {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  font-size: var(--font-size);
  margin-left: calc(var(--gutter)*0.4);
}

.angebotsboxen-suche button {
  width: 100%;
  text-align: center;
}


/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .angebotsboxen-start-innerwrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .angebotsbox-start {
    margin-bottom: 0;
  }

  .angebotsbox-start,
  .angebotsboxen-suche {
    position: relative;
    width: 32%;
  }

  .angebotsbox-start .angebotsbox-start-content {
    margin-bottom: calc(var(--gutter)*4);
  }

  .angebotsbox-start a.btn-primary {
    position: absolute;
    bottom: var(--gutter);
    left: 50%;
    transform: translateX(-50%);
    width: 87%;
    margin-left: 0;
    margin-right: 0;
  }

  /* Suche */
  .angebotsboxen-suche {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 32%;
    padding-bottom: var(--gutter);
  }
  .angebotsboxen-suche-head div:first-of-type {
    width: 3rem;
    height: 3rem;
  }

  .angebotsboxen-suche-head div:nth-of-type(2) {
    height: 3rem;
  }

  .angebotsboxen-suche form {
    display: flex;
    flex-direction: column;
    height: 88.5%;
    width: 100%;
  }

  #formFahrzeugsuche input,
  #formFahrzeugsuche select {
    margin-bottom: calc(var(--gutter)*0.3);
  }

  .angebotsboxen-suche label {
    margin-top: 0;
    font-size: var(--font-size-small);
  }

  .angebotsboxen-suche button {
    margin-top: auto;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .angebotsbox-start a.btn-primary {
    width: 88%;
  }

  /* Suche */
  .angebotsboxen-suche form {
    height: 89%;
  }

  #formFahrzeugsuche input,
  #formFahrzeugsuche select {
    margin-bottom: calc(var(--gutter)*0.5);
  }

  .angebotsboxen-suche label {
    margin-top: calc(var(--gutter)*0.3);
    font-size: var(--font-size);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .angebotsboxen-start-wrapper h2 {
    font-size: var(--font-size-h1);
  }

  .angebotsbox-start a.btn-primary {
    width: 92%;
  }

  /* Suche */
  .angebotsboxen-suche form {
    height: 89.5%;
  }

  #formFahrzeugsuche input,
  #formFahrzeugsuche select {
    margin-bottom: calc(var(--gutter)*0.7);
  }

  .angebotsboxen-suche label {
    margin-top: calc(var(--gutter)*0.5);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .angebotsbox-start a.btn-primary {
    width: 93%;
  }

  /* Suche */
  .angebotsboxen-suche h6 {
    margin-bottom: calc(var(--gutter)*0.9);
  }

  .angebotsboxen-suche form {
    height: 90%;
  }

  #formFahrzeugsuche input,
  #formFahrzeugsuche select {
    margin-bottom: var(--gutter);
  }

  .angebotsboxen-suche label {
    margin-top: calc(var(--gutter)*0.8);
  }
}
