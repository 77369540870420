/* allgemeine Infos und viewport-sx 320px */
.gebrauchtwagen-box {
  background-color: white;
  margin-top: var(--gutter);
  border-bottom: 1px solid var(--color-grey);
  padding: var(--gutter);
}

.gebrauchtwagen-box:first-of-type {
  margin-top: 0;
}

.gebrauchtwagen-box h2 {
  margin-bottom: var(--gutter);
}

.gebrauchtwagen-box p:last-of-type {
  margin-bottom: 0;
}

/* Suche */
.suchfilter label {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
  font-size: var(--font-size-small);
  margin-left: calc(var(--gutter)*0.4);
}

.suchfilter fieldset {
  padding-top: var(--gutter);
  padding-bottom: 0;
}

#formSuchfilter input,
#formSuchfilter select {
  margin-bottom: calc(var(--gutter)*0.5);
}

#formSuchfilter select {
  height: 2.5rem;
}

/* Übersicht Ergebnisse */
#moblie-content,
.gebrauchtwagen-ergebnis {
  margin-top: calc(var(--gutter)*2);
}

.gebrauchtwagen-ergebnis:first-of-type {
  margin-top: 0;
}

.gebrauchtwagen-ergebnis-img img {
  width: 100%;
  height: auto;
  margin-bottom: var(--gutter);
}

.gebrauchtwagen-ergebnis-desc ul,
.gebrauchtwagen-ergebnis-preis ul {
  list-style-type: none;
  padding-left: 0;
}

.content-wrapper .gebrauchtwagen-ergebnis-desc li,
.content-wrapper .gebrauchtwagen-ergebnis-preis li {
  margin-top: calc(var(--gutter)*0.3);
}

.content-wrapper .gebrauchtwagen-ergebnis-desc li:first-of-type,
.content-wrapper .gebrauchtwagen-ergebnis-preis li:first-of-type {
  margin-top: 0;
}

.gebrauchtwagen-ergebnis-preis li:first-of-type {
  font-family:'Helvetica Neue LT W01_71488914';
  font-weight: normal;
}

.gebrauchtwagen-ergebnis-preis button {
  width: 100%;
  text-align: center;
  max-height: 3rem;
}

/* Detailseite */
.gebrauchtwagen-detail-wrapper .gebrauchtwagen-box button {
  margin-bottom: calc(var(--gutter)*2);
}

.swiper-slide img {
  width: 100%;
  height: auto;
}

.gebrauchtwagen-detail-info,
.gebrauchtwagen-detail-liste {
  margin-top: var(--gutter);
}

.gebrauchtwagen-detail-info ul {
  list-style-type: none;
  padding-left: 0;
}
.gebrauchtwagen-detail-info ul li {
  margin-left: 0;
}
.gebrauchtwagen-detail-liste ul {
  padding-left: var(--gutter);
}

.gebrauchtwagen-detail-liste h6 {
  margin-bottom: var(--gutter);
}

.gebrauchtwagen-detail-wrapper .link-group a {
  width: 100%;
  text-align: center;
  margin-bottom: calc(var(--gutter)*0.5);
}

.gebrauchtwagen-detail-wrapper .link-group a:last-of-type {
  margin-bottom: 0;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
  /* Suche */
  #formSuchfilter .form-suchfilter-marke,
  #formSuchfilter .form-suchfilter-farbe,
  #formSuchfilter .form-suchfilter-kraftstoff,
  #formSuchfilter .form-suchfilter-aufbauart {
    margin-right: calc(var(--gutter)*0.5)
  }

  #formSuchfilter .form-suchfilter-modell,
  #formSuchfilter .form-suchfilter-leistung,
  #formSuchfilter .form-suchfilter-erstzulassung,
  #formSuchfilter .form-suchfilter-preis {
    margin-left: calc(var(--gutter)*0.5)
  }

  /* Übersicht Ergebnisse */
  .gebrauchtwagen-ergebnis-img {
    width: 50%;
  }

  .gebrauchtwagen-ergebnis-img img {
    margin-bottom: 0;
  }

  .gebrauchtwagen-ergebnis-desc {
    margin-right: var(--gutter);
  }

  .gebrauchtwagen-ergebnis-desc ul {
    margin-bottom: 0;
  }

  .gebrauchtwagen-ergebnis-preis li {
    text-align: right;
  }

  /* Detailseite */
  .gebrauchtwagen-detail-liste ul {
    column-count: 2;
    column-gap: 3rem;
  }

  .gebrauchtwagen-detail-wrapper .link-group {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .gebrauchtwagen-detail-wrapper .link-group a {
    width: 48%;
    text-align: center;
    margin-bottom: 0;
  }
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  /* Übersicht Ergebnisse */
  #moblie-content,
  .gebrauchtwagen-box {
    margin-top: calc(var(--gutter)*1);
  }

  .gebrauchtwagen-ergebnis-img {
    width: 100%;
  }

  .gebrauchtwagen-ergebnis-desc {
    margin-right: var(--gutter);
    margin-left: var(--gutter);
  }

  /* Detailansicht */
  .gebrauchtwagen-detail-info ul {
    column-count: 2;
    column-gap: 3rem;
  }

  .gebrauchtwagen-detail-liste ul {
    column-count: 3;
    column-gap: 3rem;
  }

  .gebrauchtwagen-detail-liste {
    margin-top: calc(var(--gutter)*2);
  }

  .gebrauchtwagen-detail-wrapper .link-group a {
    width: 45%;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  #moblie-content,
  .gebrauchtwagen-box {
    margin-top: calc(var(--gutter)*1.25);
  }

  .gebrauchtwagen-ergebnis-preis {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .gebrauchtwagen-ergebnis-preis a {
    display: table;
    width: 100%;
    text-align: center;
    margin-top: auto;
  }

  .gebrauchtwagen-detail-wrapper .link-group {
    margin-top: var(--gutter);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  #moblie-content,
  .gebrauchtwagen-box {
    margin-top: calc(var(--gutter)*1.5);
  }

  /* Suche */
  #formSuchfilter .form-suchfilter-marke,
  #formSuchfilter .form-suchfilter-modell,
  #formSuchfilter .form-suchfilter-leistung,
  #formSuchfilter .form-suchfilter-kraftstoff,
  #formSuchfilter .form-suchfilter-aufbauart,
  #formSuchfilter .form-suchfilter-preis {
    margin-right: calc(var(--gutter)*0.5)
  }

  #formSuchfilter .form-suchfilter-modell,
  #formSuchfilter .form-suchfilter-farbe,
  #formSuchfilter .form-suchfilter-kraftstoff,
  #formSuchfilter .form-suchfilter-erstzulassung,
  #formSuchfilter .form-suchfilter-preis {
    margin-left: calc(var(--gutter)*0.5)
  }

  #formSuchfilter .form-suchfilter-farbe {
    margin-right: 0;
  }

  #formSuchfilter .form-suchfilter-leistung {
    margin-left: 0;
  }

  /* Detailansicht */
  .gebrauchtwagen-detail-liste {
    margin-top: calc(var(--gutter)*3);
  }

  .gebrauchtwagen-detail-wrapper .link-group {
    margin-top: calc(var(--gutter)*2);
  }

  .gebrauchtwagen-detail-wrapper .link-group a {
    width: 35%;
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  #moblie-content,
  .gebrauchtwagen-box {
    margin-top: calc(var(--gutter)*1.75);
  }

  /* Suche */
  #formSuchfilter .form-suchfilter-marke,
  #formSuchfilter .form-suchfilter-modell,
  #formSuchfilter .form-suchfilter-farbe,
  #formSuchfilter .form-suchfilter-kraftstoff,
  #formSuchfilter .form-suchfilter-erstzulassung,
  #formSuchfilter .form-suchfilter-aufbauart {
    margin-right: calc(var(--gutter)*0.5)
  }

  #formSuchfilter .form-suchfilter-modell,
  #formSuchfilter .form-suchfilter-farbe,
  #formSuchfilter .form-suchfilter-leistung,
  #formSuchfilter .form-suchfilter-kraftstoff,
  #formSuchfilter .form-suchfilter-erstzulassung,
  #formSuchfilter .form-suchfilter-aufbauart,
  #formSuchfilter .form-suchfilter-preis {
    margin-left: calc(var(--gutter)*0.5)
  }

  #formSuchfilter .form-suchfilter-leistung,
  #formSuchfilter .form-suchfilter-preis {
    margin-right: 0;
  }

  #formSuchfilter .form-suchfilter-kraftstoff {
    margin-left: 0;
  }

  .gebrauchtwagen-ergebnis-preis a {
    width: 70%;
  }

  /* Detailansicht */
  .gebrauchtwagen-detail-wrapper h2 {
    text-align: center;
  }

  #content-slider {
    width: 48rem;
  }

  .gebrauchtwagen-detail-info ul {
    column-count: 3;
    column-gap: 2rem;
  }

  .gebrauchtwagen-detail-wrapper .link-group {
    margin-top: calc(var(--gutter)*4);
  }
}
