/* allgemeine Infos und viewport-sx 320px */
.slider-content {
  width: 95%;
  color: white;
  background-color: var(--color-secondary);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-top: calc(var(--gutter)*0.5);
  padding-bottom: var(--gutter);
  margin-top: -0.5rem;
  margin-left: auto;
  margin-right: auto;
}

.slider-content h3 {
  font-family:'Helvetica Neue LT W01_71488914';
  font-size: var(--font-size-h4);
  margin-bottom: calc(var(--gutter)*0.5);
}

.slider-content p:last-of-type {
  margin-bottom: 0;
  line-height: 1.2;
}

/* viewport-sm 568px */
@media screen and (min-width: 35.5rem) {
}

/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .slider-img {
    position: relative;
  }

  .slider-img a.slider-button {
    position: absolute;
    bottom: calc(var(--gutter)*1.7);
    right: 2.5%;
  }

  .slider-content {
    margin-top: -1rem;
  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .slider-img a.slider-button {
    bottom: calc(var(--gutter)*3);
  }

  .slider-content {
    margin-top: -2rem;
  }

  .slider-content h3 {
    font-size: var(--font-size-h3);
  }
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
  .slider-img a.slider-button {
    bottom: calc(var(--gutter)*4);
  }
  .slider-content {
    margin-top: -2.5rem;
  }

  .slider-content p {
    font-size: var(--font-size-h5);
  }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
  .slider-img a.slider-button {
    bottom: calc(var(--gutter)*5);
  }
}
