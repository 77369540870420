.standort--box {
  background-color: white;
  margin-top: var(--gutter);
  border-bottom: 1px solid var(--color-grey);
  padding: var(--gutter);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.standort--box > div {
  width: 100%;
}
.standort--box-map > div {
  width: 100%;
}
.standort--kontakt {
  padding-top: 1rem;
}
.standort--kontakt a{
  color: var(--font-color);
}
.standort--kontakt a:hover {
  color: var(--color-grey);
}
@media screen and (min-width: 35.5rem) {

}
@media screen and (min-width: 48rem) {
  div.standort--zeiten,
  div.standort--kontakt {
    width: 50%;
  }
  div.standort--zeiten {
    margin-top: 1rem;
  }
}
@media screen and (min-width: 80rem) {
  .standort--box > div {
    width: 33.3333%;
  }
  .standort--box-map > div {
    width: 100%;
  }
  .standort--kontakt {
    padding-top: 0;
    padding-left: 1rem;
  }
}